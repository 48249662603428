import htmx from 'htmx.org';
import {initBootstrap, loadComponents} from "../bootstrap";

// https://htmx.org/docs/#webpack
window.htmx = htmx;

// https://htmx.org/extensions/ajax-header/
htmx.defineExtension('ajax-header', {
    onEvent: function (name, evt) {
        if (name === "htmx:configRequest") {
            evt.detail.headers['X-Requested-With'] = 'XMLHttpRequest';
        }
    }
});

// https://htmx.org/docs/#request-operations
['htmx:afterSettle', 'htmx:wsAfterMessage'].forEach((event) => {
    htmx.on(event, function (elt) {
        initBootstrap(elt.target);
        loadComponents(elt.target);
    })
})

htmx.on("htmx:configRequest", function (event) {
    let pathWithParameters = event.detail.path.replace(/:([A-Za-z0-9_]+)/g, function (_match, parameterName) {
        let parameterValue = event.detail.parameters[parameterName]
        delete event.detail.parameters[parameterName]

        return parameterValue
    })

    // prevent invalid urls when parameterValue is empty
    event.detail.path = pathWithParameters.replaceAll('//', '/')
})

// https://django-debug-toolbar.readthedocs.io/en/latest/installation.html#htmx
htmx.on("htmx:afterSettle", function (detail) {
    if (
        typeof window.djdt !== "undefined"
        && detail.target instanceof HTMLBodyElement
    ) {
        djdt.show_toolbar();
    }
});